import Auth from "@/libraries/Auth";
import VueRouter from 'vue-router'

const routes = [
    {
        path: '/register/:referral?',
        name: 'register',
        component: () => import('../views/auth/Registration.vue'),
        meta: {
            title: 'Registration'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/Login.vue'),
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/password_forgot',
        name: 'password_forgot',
        component: () => import('../views/auth/PasswordForgot.vue'),
        meta: {
            title: 'Forgot password'
        }
    },
    {
        path: '/password_reset/:token',
        name: 'password_reset',
        component: () => import('../views/auth/PasswordReset.vue'),
        meta: {
            title: 'Password reset'
        }
    },
    {
        path: '/email_confirm/:token',
        name: 'email_confirm',
        component: () => import('../views/auth/EmailConfirm.vue'),
        meta: {
            title: 'E-mail confirm'
        }
    },
    {
        path: '/',
        component: () => import('../views/layout/DefaultLayout.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'root',
                redirect: 'projects',
            },
            {
                name: 'settings',
                path: 'settings/:tab?',
                component: () => import('../views/settings/Main.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'users/:id/:slug',
                name: 'user-view',
                component: () => import('../views/users/Profile.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'User public profile'
                },
                children: [
                    {
                        path: '',
                        name: 'user-root',
                        redirect: 'projects',
                    },
                    {
                        path: 'projects',
                        name: 'user-projects',
                        component: () => import('../views/users/Projects.vue'),
                        meta: {
                            requiresAuth: true,
                            title: 'User projects'
                        }
                    },
                    {
                        path: 'info',
                        name: 'user-info',
                        component: () => import('../views/users/Info.vue'),
                        meta: {
                            requiresAuth: true,
                            title: 'User info'
                        }
                    },
                    {
                        path: 'badges',
                        name: 'user-badges',
                        component: () => import('../views/users/Badges.vue'),
                        meta: {
                            requiresAuth: true,
                            title: 'User badges'
                        }
                    },
                    {
                        path: 'achievements/:edition_id?',
                        name: 'user-achievements',
                        component: () => import('../views/users/Achievements.vue'),
                        meta: {
                            requiresAuth: true,
                            title: 'User achievements'
                        }
                    }
                ]
            },
            {
                path: 'projects/:id/:slug',
                name: 'project-view',
                component: () => import('../views/projects/Project.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Project single page'
                },
                children: 
                [
                    {
                        path: '',
                        name: 'root',
                        redirect: 'dashboard',
                    },
                    {
                        path: 'dashboard',
                        name: 'project-dashboard',
                        component: () => import('../views/projects/Dashboard.vue'),
                        meta: {
                            requiresAuth: true,
                            title: 'Project dashboard'
                        }
                    },
                    {
                        path: 'info',
                        name: 'project-info',
                        component: () => import('../views/projects/Info.vue'),
                        meta: {
                            requiresAuth: true,
                            title: 'Project info'
                        }
                    },
                    {
                        path: 'wordcounts',
                        name: 'project-wordcounts',
                        component: () => import('../views/projects/Wordcounts.vue'),
                        meta: {
                            requiresAuth: true,
                            title: 'Project wordcounts'
                        }
                    }
                ]
            }/*,
            {
                path: 'projects/:id/:slug',
                name: 'project-view',
                component: () => import('../views/projects/ProjectDashboard.vue'),
            }*/,
            {
                path: 'projects',
                name: 'project-main-dashboard',
                component: () => import('../views/projects/Project.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Project dashboard'
                }
            },
            {
                path: 'editions/:id/projects',
                name: 'edition-projects',
                component: () => import('../views/projects/EditionProjects.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Edition projects'
                }
            },
            {
                path: 'leagues/:id',
                name: 'league-view',
                component: () => import('../views/leagues/Leagues.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'League single page'
                }
            },
            {
                path: 'crawl',
                name: 'crawl',
                component: () => import('../views/crawl/Crawl.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'Crawl'
                }
            },
            {
                path: 'leagues',
                name: 'leagues',
                component: () => import('../views/leagues/Leagues.vue'),
                meta: {
                    title: 'Leagues',
                    requiresAuth: true
                }
            },
            {
                path: 'challenges',
                component: () => import('../views/challenges/Main.vue'),
                meta: {
                    requiresAuth: true,
                    title: 'User challenges'
                },
                children: [
                    {
                        path: '',
                        redirect: 'word-wars',
                    },
                    {
                        path: 'word-wars/editions/:edition_id',
                        name: 'wordwars-edition',
                        component: () => import('../views/challenges/WordWars.vue'),
                        meta: {
                            title: 'Word wars by edition',
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'word-wars/:id',
                        name: 'wordwars-view',
                        component: () => import('../views/challenges/WordWars.vue'),
                        meta: {
                            title: 'Word war single page',
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'word-wars',
                        name: 'wordwars',
                        component: () => import('../views/challenges/WordWars.vue'),
                        meta: {
                            title: 'Word wars',
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'challenges-bot',
                        name: 'challenges-bot',
                        component: () => import('../views/challenges/Bot.vue'),
                        meta: {
                            title: 'Challenges',
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'challenges-bot/editions/:edition_id',
                        name: 'challenges-bot-edition',
                        component: () => import('../views/challenges/Bot.vue'),
                        meta: {
                            title: 'Challenges by edition',
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: 'notifications',
                name: 'notifications',
                component: () => import('../views/notifications/Notifications.vue'),
                meta: {
                    title: 'Notifications list',
                    requiresAuth: true
                }
            },
            {
                path: 'donate',
                name: 'donate',
                component: () => import('../views/donation/Donation.vue'),
                meta: {
                    title: 'Donate',
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('../views/landing/Calendar.vue'),
        meta: {
            title: 'Il nostro calendario'
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/landing/Privacy.vue'),
        meta: {
            title: 'Privacy page'
        }
    },
    {
        path: '/cookies',
        name: 'cookies',
        component: () => import('../views/landing/Cookies.vue'),
        meta: {
            title: 'Cookies Policy page'
        }
    },
    {
        path: '/wrimobot',
        name: 'wrimobot',
        component: () => import('../views/landing/Bot.vue'),
        meta: {
            title: 'WriMoBot landing'
        }
    },
    /*{
        path: '/crawl-landing',
        name: 'crawl-landing',
        component: () => import('../views/landing/Crawl.vue'),
        meta: {
            title: 'Craw landing'
        }
    },*/
    {
        path: '/leagues-landing',
        name: 'leagues-landing',
        component: () => import('../views/landing/Leagues.vue'),
        meta: {
            title: 'Leagues landing'
        }
    },
    /*{
        path: '/caccia-uova',
        name: 'eggs',
        component: () => import('../views/landing/Eggs.vue'),
        meta: {
            title: 'Caccia alle uova landing'
        }
    },
    {
        path: '/chi-siamo',
        name: 'about',
        component: () => import('../views/landing/About.vue'),
        meta: {
            title: 'Chi siamo'
        }
    },*/
    /*{
        path: '/MtH1YNRG8y',
        name: 'egg3',
        component: () => import('../views/landing/Egg3.vue'),
        meta: {
            title: 'Caccia alle uova a El Tucano Loco - 3'
        }
    },
    {
        path: '/ymw2s16yV2',
        name: 'egg11',
        component: () => import('../views/landing/Egg11.vue'),
        meta: {
            title: 'Caccia alle uova a El Tucano Loco - 11'
        }
    },*/
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/landing/Home.vue'),
        meta: {
            title: 'Homepage'
        }
    },
    {
        path: '*',
        component: () => import('../views/NotFound.vue')
    }
];

export default (store) => {
    const router = new VueRouter({
        mode: 'history',
        routes: routes,
        scrollBehavior: (to, from, savedPosition) => ({x: 0, y: 0}),
    });

    router.beforeEach((to, from, next) => {        
        const isAdmin = () => store.getters['user/isAdmin'];
        if (to.path === '/' && !Auth.isAuthenticated())
        {
            return next({
                path: '/home',
                params: {
                    nextUrl: to.fullPath
                }
            });
        }
        else
        {
            for (const route of to.matched) {
                if (Auth.isAuthenticated()) { // if logged
                    if (route.path === '/login') { // redirect to / if logged
                        return next({
                            path: '/',
                            params: {
                                nextUrl: to.fullPath
                            }
                        });
                    } else if (route.meta.requiresAdmin && isAdmin()) { // redirect to /login if not admin
                        return next({
                            path: '/login',
                            params: {
                                nextUrl: to.fullPath
                            }
                        })
                    }
                } 
                else { // if not logged
                    if (route.meta.requiresAuth) { // redirect to home if not logged
                        return next({
                            path: '/home',
                            params: {
                                nextUrl: to.fullPath
                            }
                        });
                    }
                }
            }
        }

        next();
    });

    const push = router.push;
    router.push = function (...args) {
        const p = push.apply(router, args);
        if (p && p.then) {
            return p.catch((failure) => {
                // if (VueRouter.isNavigationFailure(failure, VueRouter.NavigationFailureType.redirected)) {
                    console.error('Navigation Failure!', failure);
                // }
            });
        } else {
            return p;
        }
    }
    return router;
};
