import './assets/scss/custom.scss';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/it';
dayjs.extend(utc);
// dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.locale('it')
// dayjs.tz.setDefault(dayjs.tz.guess());


import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import App from './App.vue';
import createStore from './store/';
import createRouter from './router/';
import Auth from "@/libraries/Auth";
import CookiesPolicy from "@/libraries/CookiesPolicy";
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css';
import { Settings } from 'luxon';
//set to display dates for English language
Settings.defaultLocale = 'it';

import VueGtag from "vue-gtag";

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Datetime);
Vue.use(require('vue-cookies'));

Vue.config.productionTip = false;

const store = createStore();
const router = createRouter(store);

let p;
if (Auth.isAuthenticated()) 
{
    p = store.dispatch('user/get').catch(() => {
        Auth.unsetAll();
    });
} else {
    p = Promise.resolve();
}

let c = new CookiesPolicy(Vue);
Vue.prototype.$cookiesPolicy = c;

Vue.filter('formatThousands', function (value) {
    if (!value) return 0;
    const n = Number(value);
    return n.toLocaleString('it-IT');
});

Vue.filter('formatPerc', function (value) {
    if (!value) return 0;
    return value.toString().replace(".",",")    
});

Vue.filter('nl2br', function (value) {
    if (!value) return null;

    const breakTag = '<br />';
    const replaceStr = '$1'+ breakTag +'$2';
    return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);

});

Vue.filter('capitalize', function(value)
{
    if (!value) return null;
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.mixin({
    methods: {
      isActiveLink: function (routeName, currentRoute) {
        if (Array.isArray(routeName))
        {
            if (routeName.find((e) => e === currentRoute.name))
            {
                return true;
            }
            return false;
        }
        return routeName === currentRoute.name;
      },
      returnUserPublicUrl(user)
      {
        return window.location.origin + this.$router.resolve({name: 'user-view', 
        params: {'id': user.id, 'slug' : user.slug}}).href;
      }
    },
});

import { ANALYTICS_CODE } from '@/config';
if (c.isAnalyticsEnabled())
{
    if (ANALYTICS_CODE !== null)
    {
        Vue.use(VueGtag, {
            config: { id: ANALYTICS_CODE},
            pageTrackerTemplate(to) {
                return {
                  page_title: to.meta.title,
                  page_path: to.path
                }
            },
            enabled: true
        }, router);
    }
}



p.finally(() => {
    new Vue({
        el: '#app',
        store,
        router,
        render: h => h(App),
    });
});
